import Layout from '../../layouts'
import * as React from 'react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { Container } from '../../components'
import ReactMarkdown from 'react-markdown'

const Behavior = ({ data, pageContext: context, location }) => {
  console.log(data)
  const { body } = data.strapiBehavior
  const [title, content] = body.split('/behavior_lists/')
  const str = content.replace(
    /\/uploads\//,
    'https://admin.afonpodvorie.com/uploads/',
  )
  return (
    <Layout
      data={data.strapiBehavior}
      meta={context?.meta}
      menu={context?.menu}
      locale={context?.locale}
      location={location}>
      <SContainer>
        <SReactMarkdown skipHtml>{title}</SReactMarkdown>
        <BehaviorList>
          {data.strapiBehavior.behavior_lists.map(item => (
            <BehaviorItem key={item.id}>
              <ItemImage
                src={`https://admin.afonpodvorie.com${item.icon.url}`}
                alt={item?.title}
              />
              <span>{item?.title}</span>
            </BehaviorItem>
          ))}
        </BehaviorList>
        <SReactMarkdown skipHtml>{str}</SReactMarkdown>
      </SContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BehaviorTemplate($locale: String!) {
    strapiBehavior(locale: { eq: $locale }) {
      id
      title
      body
      behavior_lists {
        title
        icon {
          url
        }
      }
      background {
        url
      }
    }
  }
`

const ItemImage = styled.img`
  margin-top: -0.4rem;
`

const BehaviorItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
`

const BehaviorList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  line-height: 30px;
`

const SReactMarkdown = styled(ReactMarkdown)`
  margin: 4rem 0;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 2.2rem;
    margin: 0;
    margin-bottom: 2rem;
  }

  h6 {
    display: block;
    padding: 30px;
    background: #cac297;
    font-weight: normal;
    line-height: 28px;
  }

  img {
    padding-top: 30px;
    width: 100%;
  }

  ul {
    list-style-type: none;

    li {
      position: relative;
      line-height: 28px;
      font-size: 18px;
      &:marker {
        content: '';
        display: none;
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        left: -1.5rem;
        top: 50%;
        transform: translate(0, -50%);
        border-radius: 99999px;
        width: 8px;
        height: 8px;
        background: #cac297;
      }
    }
  }

  blockquote {
    position: relative;
    margin: 0;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 10px;
      height: 100%;
      background: #cac297;
    }
    & > p {
      margin: 0;
      margin-left: 2rem;
      font-size: 36px;
      line-height: 46px;
    }
  }
`

const SContainer = styled(Container)`
  display: flex;
  flex-direction: column;
`

export default Behavior
